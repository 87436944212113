<template>
  <button
    @click="click"
    type="button"
    :class="[
      disabled ? 'opacity-50 bg-gray-300 cursor-not-allowed' : '',
      isLoading ? 'pl-6' : 'px-6',
      'flex justify-center items-center space-x-2 rounded-md border border-transparent bg-indigo-50 text-indigo-700 py-2 text-sm font-medium shadow-sm hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-100 focus:ring-offset-2 px-3',
    ]"
  >
    <slot />
    <span v-if="isLoading" class="ml-2">
      <Spinner class="h-4 w-4 text-black" />
    </span>
  </button>
</template>

<script setup lang="ts">
import Spinner from '../Loaders/Spinner.vue';

const props = defineProps<{
  disabled?: boolean;
  isLoading?: boolean;
}>();

const emits = defineEmits<{
  (events: 'secondary-button-clicked'): void;
}>();

const click = () => {
  if (props.disabled) return;
  emits('secondary-button-clicked');
};
</script>
