import axios, { AxiosRequestConfig, CanceledError } from 'axios';
import ClientError from './clientError';

const controllers: Record<string, AbortController> = {};

export interface ApiClientInterface {
  /**
   * @deprecated use getWithError instead
   */
  get<T>(url: string, params?: Record<string, unknown>): Promise<T>;

  getWithError<T>(url: string, params?: Record<string, unknown>): Promise<T>;

  /**
   * @deprecated use postWithError instead
   */
  post<T>(url: string, data?: Record<string, unknown>): Promise<T>;

  postWithError<T>(
    url: string,
    data?: Record<string, unknown> | FormData,
    config?: AxiosRequestConfig,
  ): Promise<T>;

  /**
   * @deprecated use putWithError instead
   */
  put<T>(url: string, data?: Record<string, unknown>): Promise<T>;

  putWithError<T>(
    url: string,
    data?: Record<string, unknown> | FormData | File,
    config?: AxiosRequestConfig,
  ): Promise<T>;

  patch<T>(url: string, data?: Record<string, unknown>): Promise<T>;

  delete<T>(url: string): Promise<T>;

  authorize(token: string): void;

  download(url: string, data?: Record<string, unknown>): void;

  upload<T>(
    url: string,
    data: FormData,
    config?: AxiosRequestConfig,
  ): Promise<T>;

  patchWithError<T>(url: string, data?: Record<string, unknown>): Promise<T>;

  deleteWithError(url: string): Promise<void>;

  postValidate<T>(
    url: string,
    data?: Record<string, unknown>,
    fields?: string[],
  ): Promise<T>;

  getFile(url: string, params?: Record<string, unknown>): Promise<Blob>;
}

const createApiClient = (): ApiClientInterface => {
  axios.defaults.baseURL = import.meta.env.VITE_API_HOST;
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Accept'] = 'application/json';
  return {
    getFile(url: string, params?: Record<string, unknown>): Promise<Blob> {
      return axios
        .get(url, {
          params,
          responseType: 'blob',
        })
        .then((response) => response.data)
        .catch((error) => {
          if (error.response && error.response.data) {
            throw new ClientError(
              error.response.data.message ?? '',
              error.response.data.errors,
              error.response.status,
            );
          }
          throw error;
        });
    },
    postValidate<T>(
      url: string,
      data?: Record<string, unknown>,
      fields?: string[],
    ): Promise<T> {
      return axios
        .post(url, data, {
          headers: {
            'Content-Type': 'application/json',
            Precognition: true,
            ...(fields &&
              fields.length > 0 && {
                'Precognition-Validate-Only': fields.join(),
              }),
          },
        })
        .then((response) => response.data)
        .catch((error) => {
          if (error.response && error.response.data) {
            throw new ClientError(
              error.response.data.message ?? '',
              error.response.data.errors,
              error.response.status,
            );
          }
          throw error;
        });
    },
    deleteWithError(url: string): Promise<void> {
      return axios
        .delete(url)
        .then((response) => response.data)
        .catch((error) => {
          if (error.response && error.response.data) {
            throw new ClientError(
              error.response.data.message ?? '',
              error.response.data.errors,
              error.response.status,
            );
          }
          throw error;
        });
    },
    patchWithError<T>(url: string, data?: Record<string, unknown>): Promise<T> {
      return axios
        .patch(url, data)
        .then((response) => response.data)
        .catch((error) => {
          if (error.response && error.response.data) {
            throw new ClientError(
              error.response.data.message ?? '',
              error.response.data.errors,
              error.response.status,
            );
          }
          throw error;
        });
    },
    getWithError<T>(url: string, params?: Record<string, unknown>): Promise<T> {
      controllers[url] = new AbortController();
      return axios
        .get(url, { params, signal: controllers[url].signal })
        .then((response) => response.data)
        .catch((error) => {
          if (error.response && error.response.data) {
            throw new ClientError(
              error.response.data.message ?? '',
              error.response.data.errors,
              error.response.status,
            );
          }

          throw error;
        });
    },
    get: (url, params) =>
      axios.get(url, { params }).then((response) => response.data),
    post: (url, data) =>
      axios.post(url, data).then((response) => response.data),
    postWithError<T>(
      url: string,
      data?: Record<string, unknown>,
      config?: AxiosRequestConfig,
    ): Promise<T> {
      return axios
        .post(url, data, config)
        .then((response) => response.data)
        .catch((error) => {
          if (error.response && error.response.data) {
            throw new ClientError(
              error.response.data.message ?? '',
              error.response.data.errors,
              error.response.status,
            );
          }
          throw error;
        });
    },
    put: (url, data) => axios.put(url, data).then((response) => response.data),
    putWithError<T>(
      url: string,
      data?: Record<string, unknown>,
      config?: AxiosRequestConfig,
    ): Promise<T> {
      return axios
        .put(url, data, config)
        .then((response) => response.data)
        .catch((error) => {
          if (error.response && error.response.data) {
            throw new ClientError(
              error.response.data.message ?? '',
              error.response.data.errors,
              error.response.status,
            );
          }
          throw error;
        });
    },
    patch: (url, data) =>
      axios.patch(url, data).then((response) => response.data),
    delete: (url) => axios.delete(url).then((response) => response.data),
    authorize: (token) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    download: (url, data) => {
      window.open(
        axios.getUri({
          url,
          params: data,
        }),
        '_blank',
      );
    },
    upload<T>(
      url: string,
      data: FormData,
      config?: AxiosRequestConfig,
    ): Promise<T> {
      return axios
        .post(url, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Transfer-Encoding': 'chunked',
          },
          ...config,
        })
        .then((response) => response.data)
        .catch((error) => {
          if (error.response && error.response.data) {
            throw new ClientError(
              error.response.data.message ?? '',
              error.response.data.errors,
              error.response.status,
            );
          }
          throw error;
        });
    },
  };
};

export const apiClient = createApiClient();
export const abortRequest = (url: string) => {
  if (controllers[url]) {
    controllers[url].abort();
  }
};
